<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item :to="{ name: 'home_article' }">文章管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{infoForm._id ? '编辑文章' : '添加文章'}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <el-button type="primary" @click="onSubmitInfo">确认添加</el-button>
                <el-button @click="goBackPage" icon="arrow-left">返回</el-button>
            </div>
        </div>
        <div class="content-main">
            <div class="form-table-box">
                <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
                    
                    <el-form-item label="文章缩略图" prop="article_pic_url" v-if="infoForm.article_pic_url"
                                  class="image-uploader-diy new-height">
                        <img v-if="infoForm.article_pic_url" :src="infoForm.article_pic_url" class="image-show">
                        <el-button class="dele-list-pic" type="primary" @click="delePicList">
                            <i class="fa fa-trash-o"></i>
                        </el-button>
                    </el-form-item>
                    
                    <el-form-item label="文章缩略图" prop="article_pic_url" v-if="!infoForm.article_pic_url">
                        <el-upload
                                name="file"
                                class="upload-demo"
                                :action="qiniuZone"
                                :on-success="handleUploadListSuccess"
                                :before-upload="getQiniuToken"
                                list-type="picture-card"
                                :data="picData"
                        >

                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">图片长宽：365*100 | 只能上传jpg/png文件</div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="文章名" prop="article_name">
                        <el-input v-model="infoForm.article_name"></el-input>
                    </el-form-item>

                    <el-form-item label="文章简介" prop="article_des">
                        <el-input  v-model="infoForm.article_des"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="关联文章连接" prop="article_url">
                        <el-input  v-model="infoForm.article_url"></el-input>
                    </el-form-item>

                     <el-form-item label="排序" prop="sort_order">
                        <el-input-number :mini="1" :max="100" v-model="infoForm.sort_order"></el-input-number>
                    </el-form-item>
                    
                    <el-form-item>
                        <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                        <el-button @click="goBackPage">返回列表</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/config/api';
    import $ from 'jquery'
    export default {
        data() {
            return {
                root: '',
                qiniuZone:'',
                picData: {
                    token: ''
                },
                uploaderHeader: {
                    'LuoShao-Token': localStorage.getItem('token') || '',
                },
                infoForm: {
                    article_pic_url: '',
                    article_url: '',
                    article_name:'',
                    article_des:'',
                    sort_order:1,
                    // is_index: false,
                },
                infoRules: {
                    article_name: [
                        {required: true, message: '请填写文章名称', trigger: 'blur'},
                    ],
                    article_url: [
                        {required: true, message: '请填写文章连接', trigger: 'blur'},
                    ],
                    article_pic_url: [
                        {required: true, message: '请上传文章缩略图', trigger: 'blur'},
                    ],
                },
                nameTipsArray:[],//用于存放下拉提醒框中数据的数组
		        nameArray:['name1','name2','name3','wocao','adsg','hgjghj'],//页面初始化时全部数据
            }
        },
        watch:{
            'infoForm.article_name':{
            handler: function() {
                    this.nameTipsArray = [];//这是定义好的用于存放下拉提醒框中数据的数组
                    var len = this.nameArray.length;//nameArray是页面初始化时全部数据
                    var arr = [];
                    for (var i = 0; i < len; i++) {//根据输入框中inputName的值进行模糊匹配
                        if (this.nameArray[i].name.indexOf(this.infoForm.article_name) >= 0) {
                            arr.push(this.nameArray[i]);//符合条件的值都放入arr中
                        }
                    }

                    console.log(arr);
                    
                    //el-autocomplete元素要求数组内是对象，且有value属性，此处做一个格式调整
                    for (var i = 0; i < arr.length; i++) {
                    var obj = { value: "" };
                    obj.value = arr[i].name;
                    obj.id = arr[i]._id;
                    this.nameTipsArray.push(obj);
                    }
                }
            }
        },
        methods: {
            handleSelect(item) {
                //console.log("item item item item item item s");
               // console.log(item);
                this.infoForm.article_name = item.value;
                this.infoForm.goods_id = item.id;
                //do something
            },
            querySearch(queruString, cb){

				var nameTipsArray = this.nameTipsArray;
				cb(nameTipsArray);
		    },
            getQiniuToken() {
                let that = this
                this.axios.get('qiniu/getQiniuToken').then((response) => {
                    let resInfo = response.data.data;
                    //console.log(resInfo.token);
                    that.picData.token = resInfo.token;
                    that.url = resInfo.url;
                })
            },
            delePicList() {
                // 不删除服务器上的图片，上传新的自动替换旧的
                this.infoForm.article_pic_url = '';
            },
            beforeUpload() {
                // 显示loading动画
                this.getQiniuToken();
                this.quillUpdateImg = true
            },
            uploadError() {
                // loading动画消失
                this.quillUpdateImg = false;
                this.$message.error('图片插入失败');
            },
            goBackPage() {
                this.$router.go(-1);
            },
            onSubmitInfo() {
                this.$refs['infoForm'].validate((valid) => {
                    if (valid) {
                        
                        var binfo ={
                            article_pic_url:this.infoForm.article_pic_url,
                            article_url:this.infoForm.article_url,
                            article_des:this.infoForm.article_des,
                            article_name:this.infoForm.article_name,
                            sort_order:this.infoForm.sort_order//排序
                        }

                        if(!!this.infoForm._id)
                        {
                            this.axios.post('home/updateArticle',
                            {
                                articleinfo: binfo,
                                id:this.infoForm._id
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '更新失败'
                                })
                            }
                        })
                        }
                        else
                        {
                            this.axios.post('home/addArticle',
                            {
                                articleinfo: binfo
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '关联成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '关联失败'
                                })
                            }
                        })
                        }
                        
                    } else {
                        return false;
                    }
                });
            },
            handleUploadListSuccess(res) {
                let url = this.url;
                this.infoForm.article_pic_url = url + res.key;
                console.log(this.infoForm);
                console.log("图片已经回来了！！！");
            }
        },
        components: {
        },
        computed: {
        },
        mounted() {
            if(!!this.$route.query.articleinfo)
            {
                this.infoForm = this.$route.query.articleinfo ;

                if(!!this.infoForm._id)
                {
                    
                }
                console.log(this.infoForm);
            }
            this.getQiniuToken();
            if (this.infoForm.id > 0) {
            }
            this.root = api.rootUrl;
            this.qiniuZone = api.qiniu;
        },
    }

</script>

<style scoped>
    /* .edit_container{ */
    /*.avatar-uploader .el-upload {*/
    /*display: none;*/
    /*}*/

    .close-i{
		position: absolute;
		top:-8px;
		right: -9px;
		display: none;
		cursor: pointer;
		font-size: 18px;
		background-color: white;
		border-radius: 10px;
	}
	.newtag{
		position: relative;
		cursor: text;
		overflow: visible;
		display: inline-block;
		padding: 0;	
	}
    .addtag
    {
        margin-left: 10px;
    }
	.newtag-content{
		margin: 7px 15px; 
	}
    .dialog-header {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .dialog-header .value {
        width: 150px;
        margin-right: 14px;
    }

    .input-wrap .el-input {
        width: 200px;
        float: left;
        margin: 0 20px 20px 0;
    }

    .input-wrap .el-input input {
        background-color: #fff !important;
        color: #233445 !important;
    }

    .specFormDialig .el-input {
        width: 150px;
        margin-right: 10px;
    }

    .el-select-class {
        width: 200px;
        margin-right: 20px;
    }

    .sepc-form {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .spec-form-wrap {
        margin-top: 0 !important;
    }

    .add-spec {
        margin-top: 10px;
    }

    .spec-form-wrap .header {
        display: flex;
        justify-content: flex-start;
    }

    .spec-form-wrap .header .l {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    .spec-form-wrap .header .m {
        width: 200px;
        margin-right: 20px;
    }

    /*.sepc-form div{*/
    /*margin-left: 0;*/
    /*}*/

    .float-right {
        float: right;
    }

    .sepc-form .el-input {
        width: 200px;
        margin-right: 20px;
    }

    .marginTop20 {
        margin-top: 20px;
    }

    .checkbox-wrap .checkbox-list {
        float: left;
        margin-right: 20px;
    }

    .upload_ad{
        display: none;
    }
    .upload_ad .el-upload--picture-card {
        display: none;
    }

    .ql-container {
        min-height: 200px;
        max-height: 400px;
        overflow-y: auto;
    }

    .image-uploader-diy {
        /*height: 200px;*/
        position: relative;
    }

    /*.dele-list-pic {*/
        /*position: absolute;*/
        /*left: 380px;*/
        /*top: 0px;*/
    /*}*/

    .image-uploader-diy .el-upload {
        border: 1px solid #d9d9d9;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .image-uploader-diy .el-upload:hover {
        border-color: #20a0ff;
    }

    .image-uploader-diy .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .image-uploader-diy .image-show {
        min-width: 105px;
        height: 105px;
        display: block;
    }

    .image-uploader-diy .new-image-uploader {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-show {
        width: 165px;
        height: 105px;
        display: block;
    }

    .item-url-image-fuzhu .el-input {
        width: 260px;
    }

    .hidden {
        display: none;
    }
</style>
